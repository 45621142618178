import * as React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import homeIcon from '../assets/enmice-blanco.webp';
import darkHomeIcon from '../assets/Logotipos/ENMICE/Logotipo ENMICE NG.webp';
import { Link } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import { useLocation } from 'react-router-dom';

function AppAppBar({ mode, toggleColorMode }) {
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const isSpecificPage = location.pathname === '/academia';

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: 'smooth' });
      window.scrollTo({
        top: targetScroll,
        behavior: 'smooth',
      });
      setOpen(false);
    }
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="fixed"
      sx={{ boxShadow: 0, bgcolor: 'transparent', backgroundImage: 'none', mt: 2 }}
    >
      <Container maxWidth="lg">
        <Toolbar
          variant="regular"
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexShrink: 0,
            borderRadius: '999px',
            backdropFilter: 'blur(24px)',
            maxHeight: 40,
            border: '1px solid',
            borderColor: 'divider',
            bgcolor: 'hsla(220, 60%, 99%, 0.6)',
            boxShadow: isSpecificPage
            ? '0 1px 2px hsl(210, 100%, 80%), 0 2px 12px hsl(210, 100%, 80%)'
            : '0 1px 2px hsl(30, 100%, 80%), 0 2px 12px hsl(30, 100%, 80%)',
            ...theme.applyStyles('dark', {
              bgcolor: 'hsla(220, 0%, 0%, 0.7)',
              boxShadow:
                '0 1px 2px hsla(210, 0%, 0%, 0.5), 0 2px 12px hsla(210, 100%, 25%, 0.3)',
            }),
          })}
        >
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', px: 0 }}>
            <Box sx={{ display: { xs: 'none', md: 'flex', margin: 'auto' } }}>
              <Button
                component={Link}
                to="/about-us"
                variant="text"
                color="info"
                size="small"
              >
                Nosotros
              </Button>
              <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="text"
                color="info"
                size="small"
                onClick={handleClick}
              >
                Ediciones
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={handleClose} component={Link}
                to="/enmice2021">ENMICE 2021</MenuItem>
                <MenuItem onClick={handleClose} component={Link}
                to="/enmice2022">ENMICE 2022</MenuItem>
                <MenuItem onClick={handleClose} component={Link}
                to="/enmice2023-2024">ENMICE 2023-2024</MenuItem>
                <MenuItem onClick={handleClose} component={Link}
                to="/enmice2025">ENMICE 2025</MenuItem>
              </Menu>
              <Button
                component={Link}
                to="/academia"
                variant="text"
                color="info"
                size="small"
                sx={{ minWidth: 0 }}
              >
                Academia
              </Button>
              <Button component={Link} to="/" sx={{width: 100, height: 'auto', padding: 0, marginLeft: '20px', marginRight: '20px' }}>
                <img src={mode === 'light' ? darkHomeIcon : homeIcon} alt="Home" style={{ width: '100%', height: 'auto' }} />
              </Button>
              <Button
                component={Link}
                to="/sponsors"
                variant="text"
                color="info"
                size="small"
              >
                Aliados
              </Button>
              <Button
                component={Link}
                to="/campus-chief"
                variant="text"
                color="info"
                size="small"
              >
                Campus Chief
              </Button>
              <Button
                variant="text"
                color="info"
                size="small"
                onClick={() => scrollToSection('contactUs')}
                sx={{ minWidth: 0 }}
              >
                Contacto
              </Button>
            </Box>
            <IconButton 
              edge="start"
              aria-label="menu"
              component={Link}
              //target="_blank"
              rel="noopener noreferrer"
              to="/coming-soon"
              sx={{
                color:
                  mode === 'light'
                    ? 'black'
                    : 'inherit', // Specific page color or default color
              }}
              >
              <ShoppingCartIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
              gap: 0.5,
              alignItems: 'center',
            }}
          >
            {/* <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} /> */}
          </Box>
          <Box sx={{ display: { sm: 'flex', md: 'none' } }}>
            <Box sx={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
              <IconButton component={Link} to="/" sx={{ width: 100, height: 'auto', padding: 0, marginTop: 1.5 }}>
                <img src={mode === 'light' ? darkHomeIcon : homeIcon} alt="Home" style={{ width: '100%', height: 'auto' }} />
              </IconButton>
            </Box>
            <IconButton aria-label="Menu button" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer anchor="top" open={open} onClose={toggleDrawer(false)}>
              <Box sx={{ p: 2, backgroundColor: 'background.default' }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <IconButton component={Link} to="/" sx={{width: 100, height: 'auto', padding: 0, marginLeft: '5px' }}>
                    <img src={mode === 'light' ? darkHomeIcon : homeIcon} alt="Home" style={{ width: '100%', height: 'auto' }} />
                  </IconButton>
                  <IconButton onClick={toggleDrawer(false)}>
                    <CloseRoundedIcon />
                  </IconButton>
                </Box>
                <Divider sx={{ my: 3 }} />
                <MenuItem component={Link} to="/about-us">
                  Nosotros
                </MenuItem>
                <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="text"
                color="info"
                size="small"
                onClick={handleClick}
              >
                Ediciones
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={handleClose} component={Link}
                to="/enmice2021">ENMICE 2021</MenuItem>
                <MenuItem onClick={handleClose} component={Link}
                to="/enmice2022">ENMICE 2022</MenuItem>
                <MenuItem onClick={handleClose} component={Link}
                to="/enmice2023-2024">ENMICE 2023-2024</MenuItem>
                <MenuItem onClick={handleClose} component={Link}
                to="/enmice2025">ENMICE 2025</MenuItem>
              </Menu>
                <MenuItem component={Link} to="/academia">Academia</MenuItem>
                <MenuItem component={Link} to="/sponsors">
                  Aliados
                </MenuItem>
                <MenuItem component={Link} to="/campus-chief">
                  Campus Chief
                </MenuItem>
                <MenuItem onClick={() => scrollToSection('contactUs')}>Contacto</MenuItem>
              </Box>
            </Drawer>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

AppAppBar.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
};

export default AppAppBar;
