import React from 'react';
import './App.css';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import LandingPage from './LandingPage';
import AboutUsPage from './AboutUs';
import PastEventsPage from './PastEvents';
import CollaboratorsPage from './Collaborators';
import SponsorsPage from './Sponsors';
import AcademiaPage from './Academia';
import CampusChiefPage from './CampusChief';
import ComingSoonPage from './ComingSoon';
import ENMICE2021Page from './ENMICE2021';
import ENMICE2022Page from './ENMICE2022';
import ENMICE20232024Page from './ENMICE2023-2024';
import ENMICE2025Page from './ENMICE2025';

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <div className="ENMICE Website">
        <Routes>
          <Route path="/" element={<LandingPage />} exact />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/past-events" element={<PastEventsPage />} />
          <Route path="/collaborators" element={<CollaboratorsPage />} />
          <Route path="/sponsors" element={<SponsorsPage />} />
          <Route path="/academia" element={<AcademiaPage />} />
          <Route path="/campus-chief" element={<CampusChiefPage />} />
          <Route path="/coming-soon" element={<ComingSoonPage />} />
          <Route path="/enmice2021" element={<ENMICE2021Page />} />
          <Route path="/enmice2022" element={<ENMICE2022Page />} />
          <Route path="/enmice2023-2024" element={<ENMICE20232024Page />} />
          <Route path="/enmice2025" element={<ENMICE2025Page />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;